import { createContext, useState } from "react";

const CatalogContext = createContext();


export const CatalogContextProvider = ({children}) => { 

    const [catalog, setCatalog] = useState([]);

    return(
        <CatalogContext.Provider value={{catalog, setCatalog}}>
            {children}
        </CatalogContext.Provider>
    )
}


export default CatalogContext;