import { createContext, useState } from "react";

const CompanyContext = createContext();

export const CompanyContextProvider = ({children}) => {
    const [companyData, setCompanyData] = useState([]);


    return(
        <CompanyContext.Provider value={{companyData, setCompanyData}}>
            {children}
        </CompanyContext.Provider>
    )
}


export default CompanyContext;