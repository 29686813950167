import {createContext, useState} from 'react';


const ClientDataContext = createContext();

export const ClientDataContextProvider = ({children}) => {
  const [clientData, setClientData] = useState([]);
  
  return(
    <ClientDataContext.Provider value={{clientData, setClientData}}>
      {children}
    </ClientDataContext.Provider>
  )
}

export default ClientDataContext;
