
// import '../css/screens/error-404.css';
import '../css/components/scrollbar.css';

import { AlertMessage } from '../components/alertMessage';

function Error404(){

    return(
        
        <div>
            <AlertMessage MODE={'text'} TITLE={'404'} TEXT={'Parece que esta página não existe!\n\nConsidere ler novamente o QR-Code na sua mesa e tente novamente.'} />
        </div>
    )
        
}

export default Error404;