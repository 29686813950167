import { useState, useEffect, useContext } from 'react';
import {InputMask} from '@react-input/mask';
import ReactLoading from 'react-loading';
import axios from 'axios';

// STYLE LINKS
import '../css/components/scrollbar.css';
import '../css/screens/register/register.css';

// IMPORT ICONS
import { FaChevronRight } from 'react-icons/fa';
import { BiChevronLeft } from 'react-icons/bi';

// FROM ANOTHER ARQUIVES
import NiValidator from '../scripts/documentValidator';
import { analyzeDateBirth } from '../scripts/analyzeDateBirth';
import CompanyContext from '../components/contexts/companyContext';
import ScreenContext from '../components/contexts/screenContext';
import BoxAlert from '../components/boxAlert';
import { ServerPath } from '../components/serverPath';




function Register() {
    const { companyData } = useContext(CompanyContext);

    const { setScreen } = useContext(ScreenContext);

    // TO EDIT RESERVED TABLE STATUS
    const reservedData = JSON.parse(sessionStorage.getItem('reservedTable'));

    const [phase, setPhase] = useState(1);

    const [NI, setNi] = useState(null);
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [birth, setBirth] = useState(null);

    // ALERT CONTROL
    const [warnAlert, setWarnAlert] = useState({ show: false, title: null, text: null });
    const [sucessAlert, setSucessAlert] = useState({ show: false, data: null});

    // LOADIND CONTROLLER
    const [loading, setLoading] = useState(false);

    // WHEN SCREEN IS LOAD
    useEffect(() => {
        document.title = `${companyData?.name}`;

	const data = sessionStorage.getItem('CLIENT_DOCUMENT');

	if(data){
	    setNi(JSON.parse(data));
	}
    }, []);

    // CONTROLA A MODIFICAÇÃO DE TELAS DA PÁGINA DE REGISTRO
    const handleChangeScreen = async () => {
        switch (phase) {
            case 1:
                // COUNT NAME LENGTH
                let formatName;

                if (name) {
                    formatName = name.split(' ');
                }

                // PHONE BUT ONLY NUMBERS
                let formatPhone;

                if (phone) {
                    formatPhone = phone.replace(/\(/g, '').replace(/\)/g, '').replace(' ', '').replace(/\-/g, '');
                }

                console.warn('BIRTH VALUE BEFORE SCREEN 2:', birth);

                if (formatName && 
                    formatName.length > 1 && 
                    formatName[0].length > 1 && 
                    formatName[formatName.length - 1].length > 1 && 
                    formatPhone && 
                    formatPhone.length === 11) {
                        setPhase(2);

                } else {
                    window.alert('Insira os dados corretamente para continuar!');
                }
                break;

            case 2:
                // NI BUT ONLY NUMBERS
                const validateNI = NiValidator(NI);

                if (!validateNI) {
                    window.alert('Insira um CPF válido antes de continuar!');
                    return;
                }

                // BIRTH FORMAT
                let formatBirth;

                if (!birth) {
                    formatBirth = null;
                } else {
                    let day = parseInt(birth.slice(0, 2));
                    let month = parseInt(birth.slice(3, 5));
                    let year = parseInt(birth.slice(6, 10));
                    formatBirth = `${year}-${month}-${day}`;

                    // ANALIZANDO O VALOR DE DATA DE NASCIMENTO PARA CONTROLE DE INSERÇÕES FALSAS
                    const analize = await analyzeDateBirth(formatBirth);
                    console.warn(formatBirth, analize.value);

                    const cYear = new Date().getFullYear();

                    if (!formatBirth || analize.value > 100 || analize.value <= 3 || day < 1 || day > 31 || month < 1 || month > 12 || year > cYear || year <= cYear - 100) {
                        console.log('analize:', analize.value, day, month, year, cYear);
                        window.alert('Erro:\nAnalize o valor inserido no campo "data de nascimento" e tente novamente!');
                        return;
                    }
                }

                await saveClientData();

                break;

            default:
                break;
        }
    };

    const saveClientData = async () => {
        try {
            const res = await axios.post(`${ServerPath}/smartmenu/register-client`, {
                companyId: companyData.id,
                name: name,
                NI: NI,
                phone: phone,
                birthDate: birth,
                platform: navigator.platform,
            });

            if (res.status === 201) {
                // let array = {
                //   id: res.data.id,
                //   name: name,
                //   NI: NI,
                //   phone: phone,
                //   birthDate: birthDate,
                //   older: null,
                // }

                // array.older = await analyzeDateBirth(birthDate).valid;

                const firstName = name.split(' ');

                setSucessAlert({show: true, data: firstName[0]});

            }
        } catch (error) {
            if (error.response.data === 'ERROR') {
                setWarnAlert({show: true, title: 'Erro', text: `Desculpe, tivemos um erro interno ao realizar seu cadastro! Tente novamente em instantes.`});

            } else {
                setWarnAlert({show: true, title: 'Erro', text: `Desculpe, mas não foi possível realizar o cadastro. Encontramos outro usuário com o ${error.response.data === 'NI' ? 'CPF' : 'telefone'} repassado por você!\n\nCaso não seja você, entre em contato com o suporte SofChef para que possamos resolver isso de forma rápida e simples!`});
            }
        }
    };

    // <=============================== HANDLE INPUT FUNCTIONS ==============================>

    // NAME INPUT ======================>
    const handleInputName = ({ target: { value } }) => setName(value);

    // NI INPUT ======================>
    const handleInputNi = ({ target: { value } }) => setNi(value);

    // PHONE INPUT ======================>
    const handleInputPhone = ({ target: { value } }) => setPhone(value);

    // DATE OF BIRTH INPUT ======================>
    const handleInputBirth = ({ target: { value } }) => setBirth(value);

    // SCREEN =======================================================================>
    return (
        <div style={{ flex: 1 }}>
            <div className="overlay" style={{ flex: 1, paddingVertical: 40 }}></div>

            {phase === 2 && (
                <button className="return-button" style={{ backgroundColor: '#fff' }} onClick={() => setPhase(1)}>
                    <BiChevronLeft size={24} color={'black'} />
                    <span style={{ color: 'black' }}>Voltar</span>
                </button>
            )}

            <section id="form">
                <h1 id="title-form">Registre-se</h1>
                <span id="subtitle-text">
                    Insira seus dados para participar de promoções e sorteios de brindes nos estabelecimentos parceiros do <b>SofChef</b>!
                </span>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 25 }}>
                    {phase === 1 ? (
                        <div className="form-inputs">
                            <div className="input-area">
                                <p className="label-input">Nome: *</p>
                                <input className="input" value={name} placeholder="Nome completo" onChange={handleInputName} />
                            </div>

                            <div className="input-area">
                                <p className="label-input">Telefone: *</p>
                                <InputMask className="input" mask='(__) _____-____' replacement={{_: /\d/}} value={phone} placeholder="Telefone" onChange={handleInputPhone} />
                            </div>

                            <button className="btn-step" onClick={async () => await handleChangeScreen()}>
                                <FaChevronRight color={'#fff'} />
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className="input-area">
                                <p className="label-input">CPF: *</p>
                                <InputMask className="input" mask='___.___.___-__' replacement={{_: /\d/}} value={NI} placeholder="Digite seu CPF" onChange={handleInputNi} />
                            </div>

                            <div className="input-area">
                                <p className="label-input">Data de nascimento: *</p>
                                <InputMask className="input" mask='__/__/____' replacement={{_: /\d/}} value={birth} placeholder="01/01/2000" onChange={handleInputBirth} />
                            </div>

                            <button className="btn-step" onClick={async () => await handleChangeScreen()}>
                                {loading ? <ReactLoading type={'spin'} color={'#fff'} height={25} width={25} /> : <span id="text-button">Cadastrar</span>}
                            </button>
                        </>
                    )}
                </div>
            </section>


            {/* SUCESS ALERT */}
            <BoxAlert
              show={sucessAlert.show}
              title={'Sucesso'}
              text={<p>Parabéns {sucessAlert.data}, agora você faz parte do sistema de benefícios do SofChef e estará concorrendo a promoções e brindes em suas próximas visitas ao {companyData.name}!</p>}
              onClose={() => setScreen('cart')}
            />



            {/* WARN ALERT */}
            <BoxAlert
              show={warnAlert.show}
              title={warnAlert.title}
              text={<p>{warnAlert.text}</p>}
              onClose={() => setWarnAlert({show: false, title: null, text: null})}
            />
        </div>
    );
}

export default Register;
