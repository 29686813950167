import React, {useState, useEffect} from "react";

// ANOTHER ARQUIVES
import Logo from '../images/Logo.png';
import '../css/components/alertMessage.css';
import { useSearchParams } from "react-router-dom";

export const AlertMessage = ({TITLE, TEXT, CLOSE}) => {

    return (
        <div>
            <div className="overflow"></div>
            
            <section className="alert-area">
                <h1 className="title-alert">{TITLE}</h1>
                <p className="text-alert">{TEXT}</p>
                <img src={Logo} className="logo-img" alt={'SofChef'}/>
            </section>
        </div>
    )
}
