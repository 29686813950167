
import { createContext, useState } from "react";


const ScreenContext = createContext();


export const ScreenContextProvider = ({children}) => {
    const [screen, setScreen] = useState('menu');

    return(
        <ScreenContext.Provider value={{screen, setScreen}}>
            {children}
        </ScreenContext.Provider>
    )
}


export default ScreenContext;