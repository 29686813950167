import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

// MENU ===========================>
import SmartMenu from './cardapio/smartmenu';
import Error404 from './cardapio/error-404';
import { CompanyContextProvider } from './components/contexts/companyContext';
import { ProductContextProvider } from './components/contexts/productContext';
import { CatalogContextProvider } from './components/contexts/catalogContext';
import { ScreenContextProvider } from './components/contexts/screenContext';
import { LocationContextProvider } from './components/contexts/locationContext';
import { ClientDataContextProvider } from './components/contexts/clientContext';
import { LoadingContextProvider } from './components/contexts/loadingContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <LoadingContextProvider>
        <CompanyContextProvider>
            <ClientDataContextProvider>
                <LocationContextProvider>
                    <ScreenContextProvider>
                        <CatalogContextProvider>
                            <ProductContextProvider>
                                <BrowserRouter>
                                    <Routes>
                                        {/* <=================== ROTAS PARA CARDÁPIO ===================> */}

                                        <Route path="/cardapio/:companyParams/*" index element={<SmartMenu />} />

                                        {/* ============================================================> */}

                                        <Route path="*" element={<Error404 />} />
                                    </Routes>
                                </BrowserRouter>
                            </ProductContextProvider>
                        </CatalogContextProvider>
                    </ScreenContextProvider>
                </LocationContextProvider>
            </ClientDataContextProvider>
        </CompanyContextProvider>
    </LoadingContextProvider>
);

reportWebVitals();
