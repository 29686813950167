

export async function analyzeDateBirth(value) {

    console.log('VALUE:', value);
    
    return new Promise((resolve) => {
        let timeA;
        
        if(typeof value == Object){
            timeA = new Date(value);
    
        } else {
            timeA = new Date(
                value.slice(0, 4), // ano
                value.slice(5, 7) - 1, // mês
                value.slice(8, 10), // dia
            );
    
        }
    
        let currentDate = new Date();
    
        let timeB = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
        );
    
    
        let result = Math.floor(((timeB - timeA) / 1000) / (60 * 60 * 24 * 365));
    
        console.log('Value:', value, '\nTIME A:', timeA, '\nTIME B:', timeB, '\nResult:', result);
    
        resolve({valid: result >= 18 ? true : false, value: result});

    })


}

